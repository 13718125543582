<template>
  <div class="library-main">
    <div class="container library-block">
      <nav class="breadcrumbs-block">
        <ul class="breadcrumbs-list">
          <li class="breadcrumbs-item"><a href="#">Главная</a></li>
          <li class="breadcrumbs-item">Библиотека</li>
        </ul>
      </nav>
      <h3>Библиотека</h3>
      <div class="library-filters">
        <div class="library-filter-popularity">
          <el-select v-model="popularityValue" placeholder="По популярности">
            <el-option
                v-for="item in popularityOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="library-filter-chapter">
          <el-select v-model="popularityValue" placeholder="Раздел">
            <el-option
                v-for="item in popularityOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="library-filter-author">
          <el-select v-model="popularityValue" placeholder="Автор">
            <el-option
                v-for="item in popularityOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="library-filter-year">
          <el-select v-model="popularityValue" placeholder="Год выпуска">
            <el-option
                v-for="item in popularityOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="library-list">
        <div class="library-list-item">
          <el-image
              style="width: 133px; height: 200px"
              src="/images/book-1.png"
              fit="fit"></el-image>
          <div class="book-title truncate-title-book">
            К. Кнорре-Дмитриева — Развлечение или труд? Что важнее в учебном процессе?
          </div>
          <div class="book-desc">
            Пособие для учителей. «Государственное учебно-педагогическое издательство наркомпроса РСФСР», Москва, 1945 г.
          </div>
          <button class="library-el-btn" @click="buyElBookLibrary=true">
            <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.0273 21.8879C9.69141 25.8606 4.39453 25.8606 4.39453 25.8606C4.39453 25.8606 4.39453 20.5637 8.36719 19.2278" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22.957 12.6061L15 20.5631L9.69141 15.2545L17.6484 7.2975C20.6602 4.28578 23.6719 4.32094 24.9609 4.50844C25.1598 4.53497 25.3445 4.62623 25.4864 4.76813C25.6283 4.91003 25.7196 5.09468 25.7461 5.29359C25.9336 6.58266 25.9687 9.59438 22.957 12.6061Z" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21.6328 13.9309V21.5012C21.6293 21.7485 21.5282 21.9844 21.3516 22.1575L17.5664 25.9543C17.4463 26.0743 17.2959 26.1594 17.1313 26.2006C16.9666 26.2417 16.7938 26.2374 16.6314 26.1881C16.469 26.1388 16.3231 26.0463 16.2091 25.9205C16.0951 25.7947 16.0175 25.6404 15.9844 25.4739L15 20.5637" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.3245 8.62231H8.75419C8.5069 8.62584 8.27103 8.72693 8.09794 8.90356L4.30106 12.6887C4.18111 12.8088 4.09602 12.9592 4.05485 13.1239C4.01368 13.2885 4.01799 13.4613 4.06731 13.6237C4.11664 13.7861 4.20912 13.9321 4.33491 14.046C4.46069 14.16 4.61506 14.2377 4.78153 14.2708L9.69169 15.2551" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Купить электронную версию - 1 000 ₸
          </button>
          <button class="library-paper-btn" @click="buyPaperBookLibrary=true">
            Купить бумажную версию - 3 000 ₸
          </button>
        </div>

        <div class="library-list-item">
          <el-image
              style="width: 133px; height: 200px"
              src="/images/book-1.png"
              fit="fit"></el-image>
          <div class="book-title truncate-title-book">
            К. Кнорре-Дмитриева — Развлечение или труд? Что важнее в учебном процессе?
          </div>
          <div class="book-desc">
            Пособие для учителей. «Государственное учебно-педагогическое издательство наркомпроса РСФСР», Москва, 1945 г.
          </div>
          <button class="library-el-btn" @click="buyElBookLibrary=true">
            <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.0273 21.8879C9.69141 25.8606 4.39453 25.8606 4.39453 25.8606C4.39453 25.8606 4.39453 20.5637 8.36719 19.2278" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22.957 12.6061L15 20.5631L9.69141 15.2545L17.6484 7.2975C20.6602 4.28578 23.6719 4.32094 24.9609 4.50844C25.1598 4.53497 25.3445 4.62623 25.4864 4.76813C25.6283 4.91003 25.7196 5.09468 25.7461 5.29359C25.9336 6.58266 25.9687 9.59438 22.957 12.6061Z" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21.6328 13.9309V21.5012C21.6293 21.7485 21.5282 21.9844 21.3516 22.1575L17.5664 25.9543C17.4463 26.0743 17.2959 26.1594 17.1313 26.2006C16.9666 26.2417 16.7938 26.2374 16.6314 26.1881C16.469 26.1388 16.3231 26.0463 16.2091 25.9205C16.0951 25.7947 16.0175 25.6404 15.9844 25.4739L15 20.5637" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.3245 8.62231H8.75419C8.5069 8.62584 8.27103 8.72693 8.09794 8.90356L4.30106 12.6887C4.18111 12.8088 4.09602 12.9592 4.05485 13.1239C4.01368 13.2885 4.01799 13.4613 4.06731 13.6237C4.11664 13.7861 4.20912 13.9321 4.33491 14.046C4.46069 14.16 4.61506 14.2377 4.78153 14.2708L9.69169 15.2551" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Купить электронную версию - 1 000 ₸
          </button>
          <button class="library-paper-btn" @click="buyPaperBookLibrary=true">
            Купить бумажную версию - 3 000 ₸
          </button>
        </div>

        <div class="library-list-item">
          <el-image
              style="width: 133px; height: 200px"
              src="/images/book-1.png"
              fit="fit"></el-image>
          <div class="book-title truncate-title-book">
            К. Кнорре-Дмитриева — Развлечение или труд? Что важнее в учебном процессе?
          </div>
          <div class="book-desc">
            Пособие для учителей. «Государственное учебно-педагогическое издательство наркомпроса РСФСР», Москва, 1945 г.
          </div>
          <button class="library-el-btn" @click="buyElBookLibrary=true">
            <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.0273 21.8879C9.69141 25.8606 4.39453 25.8606 4.39453 25.8606C4.39453 25.8606 4.39453 20.5637 8.36719 19.2278" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22.957 12.6061L15 20.5631L9.69141 15.2545L17.6484 7.2975C20.6602 4.28578 23.6719 4.32094 24.9609 4.50844C25.1598 4.53497 25.3445 4.62623 25.4864 4.76813C25.6283 4.91003 25.7196 5.09468 25.7461 5.29359C25.9336 6.58266 25.9687 9.59438 22.957 12.6061Z" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21.6328 13.9309V21.5012C21.6293 21.7485 21.5282 21.9844 21.3516 22.1575L17.5664 25.9543C17.4463 26.0743 17.2959 26.1594 17.1313 26.2006C16.9666 26.2417 16.7938 26.2374 16.6314 26.1881C16.469 26.1388 16.3231 26.0463 16.2091 25.9205C16.0951 25.7947 16.0175 25.6404 15.9844 25.4739L15 20.5637" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.3245 8.62231H8.75419C8.5069 8.62584 8.27103 8.72693 8.09794 8.90356L4.30106 12.6887C4.18111 12.8088 4.09602 12.9592 4.05485 13.1239C4.01368 13.2885 4.01799 13.4613 4.06731 13.6237C4.11664 13.7861 4.20912 13.9321 4.33491 14.046C4.46069 14.16 4.61506 14.2377 4.78153 14.2708L9.69169 15.2551" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Купить электронную версию - 1 000 ₸
          </button>
          <button class="library-paper-btn" @click="buyPaperBookLibrary=true">
            Купить бумажную версию - 3 000 ₸
          </button>
        </div>

        <div class="library-list-item">
          <el-image
              style="width: 133px; height: 200px"
              src="/images/book-1.png"
              fit="fit"></el-image>
          <div class="book-title truncate-title-book">
            К. Кнорре-Дмитриева — Развлечение или труд? Что важнее в учебном процессе?
          </div>
          <div class="book-desc">
            Пособие для учителей. «Государственное учебно-педагогическое издательство наркомпроса РСФСР», Москва, 1945 г.
          </div>
          <button class="library-el-btn" @click="buyElBookLibrary=true">
            <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.0273 21.8879C9.69141 25.8606 4.39453 25.8606 4.39453 25.8606C4.39453 25.8606 4.39453 20.5637 8.36719 19.2278" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22.957 12.6061L15 20.5631L9.69141 15.2545L17.6484 7.2975C20.6602 4.28578 23.6719 4.32094 24.9609 4.50844C25.1598 4.53497 25.3445 4.62623 25.4864 4.76813C25.6283 4.91003 25.7196 5.09468 25.7461 5.29359C25.9336 6.58266 25.9687 9.59438 22.957 12.6061Z" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21.6328 13.9309V21.5012C21.6293 21.7485 21.5282 21.9844 21.3516 22.1575L17.5664 25.9543C17.4463 26.0743 17.2959 26.1594 17.1313 26.2006C16.9666 26.2417 16.7938 26.2374 16.6314 26.1881C16.469 26.1388 16.3231 26.0463 16.2091 25.9205C16.0951 25.7947 16.0175 25.6404 15.9844 25.4739L15 20.5637" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.3245 8.62231H8.75419C8.5069 8.62584 8.27103 8.72693 8.09794 8.90356L4.30106 12.6887C4.18111 12.8088 4.09602 12.9592 4.05485 13.1239C4.01368 13.2885 4.01799 13.4613 4.06731 13.6237C4.11664 13.7861 4.20912 13.9321 4.33491 14.046C4.46069 14.16 4.61506 14.2377 4.78153 14.2708L9.69169 15.2551" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Купить электронную версию - 1 000 ₸
          </button>
          <button class="library-paper-btn" @click="buyPaperBookLibrary=true">
            Купить бумажную версию - 3 000 ₸
          </button>
        </div>

        <div class="library-list-item">
          <el-image
              style="width: 133px; height: 200px"
              src="/images/book-1.png"
              fit="fit"></el-image>
          <div class="book-title truncate-title-book">
            К. Кнорре-Дмитриева — Развлечение или труд? Что важнее в учебном процессе?
          </div>
          <div class="book-desc">
            Пособие для учителей. «Государственное учебно-педагогическое издательство наркомпроса РСФСР», Москва, 1945 г.
          </div>
          <button class="library-el-btn" @click="buyElBookLibrary=true">
            <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.0273 21.8879C9.69141 25.8606 4.39453 25.8606 4.39453 25.8606C4.39453 25.8606 4.39453 20.5637 8.36719 19.2278" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22.957 12.6061L15 20.5631L9.69141 15.2545L17.6484 7.2975C20.6602 4.28578 23.6719 4.32094 24.9609 4.50844C25.1598 4.53497 25.3445 4.62623 25.4864 4.76813C25.6283 4.91003 25.7196 5.09468 25.7461 5.29359C25.9336 6.58266 25.9687 9.59438 22.957 12.6061Z" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21.6328 13.9309V21.5012C21.6293 21.7485 21.5282 21.9844 21.3516 22.1575L17.5664 25.9543C17.4463 26.0743 17.2959 26.1594 17.1313 26.2006C16.9666 26.2417 16.7938 26.2374 16.6314 26.1881C16.469 26.1388 16.3231 26.0463 16.2091 25.9205C16.0951 25.7947 16.0175 25.6404 15.9844 25.4739L15 20.5637" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.3245 8.62231H8.75419C8.5069 8.62584 8.27103 8.72693 8.09794 8.90356L4.30106 12.6887C4.18111 12.8088 4.09602 12.9592 4.05485 13.1239C4.01368 13.2885 4.01799 13.4613 4.06731 13.6237C4.11664 13.7861 4.20912 13.9321 4.33491 14.046C4.46069 14.16 4.61506 14.2377 4.78153 14.2708L9.69169 15.2551" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Купить электронную версию - 1 000 ₸
          </button>
          <button class="library-paper-btn" @click="buyPaperBookLibrary=true">
            Купить бумажную версию - 3 000 ₸
          </button>
        </div>

        <div class="library-list-item">
          <el-image
              style="width: 133px; height: 200px"
              src="/images/book-1.png"
              fit="fit"></el-image>
          <div class="book-title truncate-title-book">
            К. Кнорре-Дмитриева — Развлечение или труд? Что важнее в учебном процессе?
          </div>
          <div class="book-desc">
            Пособие для учителей. «Государственное учебно-педагогическое издательство наркомпроса РСФСР», Москва, 1945 г.
          </div>
          <button class="library-el-btn" @click="buyElBookLibrary=true">
            <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.0273 21.8879C9.69141 25.8606 4.39453 25.8606 4.39453 25.8606C4.39453 25.8606 4.39453 20.5637 8.36719 19.2278" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22.957 12.6061L15 20.5631L9.69141 15.2545L17.6484 7.2975C20.6602 4.28578 23.6719 4.32094 24.9609 4.50844C25.1598 4.53497 25.3445 4.62623 25.4864 4.76813C25.6283 4.91003 25.7196 5.09468 25.7461 5.29359C25.9336 6.58266 25.9687 9.59438 22.957 12.6061Z" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21.6328 13.9309V21.5012C21.6293 21.7485 21.5282 21.9844 21.3516 22.1575L17.5664 25.9543C17.4463 26.0743 17.2959 26.1594 17.1313 26.2006C16.9666 26.2417 16.7938 26.2374 16.6314 26.1881C16.469 26.1388 16.3231 26.0463 16.2091 25.9205C16.0951 25.7947 16.0175 25.6404 15.9844 25.4739L15 20.5637" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.3245 8.62231H8.75419C8.5069 8.62584 8.27103 8.72693 8.09794 8.90356L4.30106 12.6887C4.18111 12.8088 4.09602 12.9592 4.05485 13.1239C4.01368 13.2885 4.01799 13.4613 4.06731 13.6237C4.11664 13.7861 4.20912 13.9321 4.33491 14.046C4.46069 14.16 4.61506 14.2377 4.78153 14.2708L9.69169 15.2551" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Купить электронную версию - 1 000 ₸
          </button>
          <button class="library-paper-btn" @click="buyPaperBookLibrary=true">
            Купить бумажную версию - 3 000 ₸
          </button>
        </div>
      </div>

      <div class="library-pagination">
        <el-pagination
            background
            layout="prev, pager, next"
            :total="1000">
        </el-pagination>
      </div>

    </div>

    <el-dialog
        :visible.sync="buyElBookLibrary"
        :width="modalWidth"
        center>
      <div class="modal-book-title">
        <img src="/images/books-icon.svg" width="52" height="52"/>
        <div>
          <p class="modal-book-subtitle">
            Покупка электронной версии
          </p>
          <p class="modal-book-text">
            Simple and flexible per-user pricing.
          </p>
        </div>
      </div>

      <div class="modal-book-card">
        <img src="/images/book-1.png" width="133" height="200"/>
        <div class="price-tag">
          1 000 ₸
        </div>
        <div class="book-card-desc">
          К. Кнорре-Дмитриева — Развлечение или труд? Что важнее в учебном процессе?
        </div>
      </div>

      <div>
        <button @click="buyElBook = false" class="btn-cancel">
          Отмена
        </button>
        <button class="btn-buy">
          Оплата
        </button>
      </div>
    </el-dialog>

    <el-dialog
        :visible.sync="buyPaperBookLibrary"
        :width="modalWidth"
        center>
      <div class="modal-book-title">
        <img src="/images/books-icon.svg" width="52" height="52"/>
        <div>
          <p class="modal-book-subtitle">
            Покупка бумажной версии
          </p>
          <p class="modal-book-text">
            Simple and flexible per-user pricing.
          </p>
        </div>
      </div>

      <div class="modal-book-card">
        <img src="/images/book-1.png" width="133" height="200"/>
        <div class="price-tag">
          3 000 ₸
        </div>
        <div class="book-card-desc">
          К. Кнорре-Дмитриева — Развлечение или труд? Что важнее в учебном процессе?
        </div>
      </div>

      <div>
        <button @click="buyPaperBook = false" class="btn-cancel">
          Отмена
        </button>
        <button class="btn-buy">
          Оплата
        </button>
      </div>
    </el-dialog>

    <el-dialog
        :visible.sync="downloadSelectedBook"
        :width="modalWidth"
        center>
      <div class="modal-book-title">
        <div>
          <p class="modal-book-subtitle">
            Нажмите чтобы скачать файл
          </p>
          <p class="modal-book-text">
            Upload and attach files to this project.
          </p>
        </div>
      </div>
      <div class="download-book-img">
        <img src="/images/book-download.png" width="224" height="335"/>
      </div>

      <a class="download-book-link">
        <div class="d-flex flex-column">
          <span>Tech design requirements.pdf</span>
          <span>200 KB</span>
        </div>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M14.6953 20.9468L19.9986 26.2501L25.3019 20.9468" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M20 13.75V26.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </a>
    </el-dialog>

    <el-dialog
        :visible.sync="sendingSelectedPaperBook"
        :width="modalWidthPaperBook"
        center>
      <div class="modal-book-title">
        <div>
          <p class="modal-book-subtitle">
            Покупка бумажной версии
          </p>
          <p class="modal-book-text">
            К. Кнорре-Дмитриева — Развлечение или труд? Что важнее в учебном процессе?
          </p>
        </div>
      </div>
      <div class="modal-paper-book-card">
        <img src="/images/book-1.png" width="77" height="116"/>
        <div class="d-flex flex-column align-items-center">
          <div class="book-title">
            К. Кнорре-Дмитриева — Развлечение или труд? Что важнее в учебном процессе?
          </div>
          <div class="book-price">
            3 000 ₸
          </div>
        </div>
      </div>

      <div class="sending-list-data">
        <div class="d-flex">
          <div class="sending-column-data">
            Почтовый индекс
          </div>
          <el-input placeholder="Please input" v-model="mailIndex"></el-input>
        </div>

        <div class="demarcation-line"></div>

        <div class="d-flex">
          <div class="sending-column-data">
            Страна
          </div>
          <el-input placeholder="Please input" v-model="mailIndex"></el-input>
        </div>
        <div class="d-flex">
          <div class="sending-column-data">
            Населенный пункт
          </div>
          <el-input placeholder="Please input" v-model="mailIndex"></el-input>
        </div>
        <div class="d-flex" style="gap: 20px">
          <el-input placeholder="Please input" v-model="mailIndex"></el-input>
          <el-input placeholder="Please input" v-model="mailIndex"></el-input>
          <el-input placeholder="Please input" v-model="mailIndex"></el-input>
        </div>

        <div class="demarcation-line"></div>

        <div class="d-flex">
          <div class="sending-column-data">
            ФИО*<br>
            (на русском)
          </div>
          <el-input placeholder="Please input" v-model="mailIndex"></el-input>
        </div>
        <div class="d-flex">
          <div class="sending-column-data">
            Контактный телефон *
          </div>
          <el-input placeholder="Please input" v-model="mailIndex"></el-input>
        </div>
        <div class="d-flex">
          <div class="sending-column-data">
            E-mail *
          </div>
          <el-input placeholder="Please input" v-model="mailIndex"></el-input>
        </div>

        <div class="demarcation-line"></div>

        <div class="btns-paper-book">
          <button @click="buyElBook = false" class="btn-cancel">
            Отменить
          </button>
          <button class="btn-buy">
            Подтвердить покупку
          </button>
        </div>

      </div>


    </el-dialog>
  </div>
</template>
<script>

export default {

  data() {
    return {
      popularityValue: '',
      popularityOptions: [{
        value: 'Option1',
        label: 'Option1'
      }, {
        value: 'Option2',
        label: 'Option2'
      }, {
        value: 'Option3',
        label: 'Option3'
      }, {
        value: 'Option4',
        label: 'Option4'
      }, {
        value: 'Option5',
        label: 'Option5'
      }],
      buyElBookLibrary: false,
      buyPaperBookLibrary: false,
      buyPaperBook: false,
      downloadSelectedBook: false,
      sendingSelectedPaperBook: true,
      modalWidth: "440px",
      modalWidthPaperBook: "36%",
      mailIndex: "",
    }
  },
  methods: {

  },
  mounted() {

  }
}

</script>

<style>

.library-block {
  padding-top: 75px;
}

.library-main {
  position: relative;
  padding-bottom: 100px;
}

.library-main::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 500px;
  background: linear-gradient(to right, #32436D, #1A84B2, #02C7FA);
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 20%;
  z-index: -1;
}

.library-main::after {
  position: absolute;
  content: "";
  width: 300px;
  height: 420px;
  right: -60px;
  bottom: 270px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/images/library-back.png");
}

.library-block h3 {
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #FFF;
}

.library-block .el-input__inner {
  background-color: transparent;
}

.library-filters {
  display: flex;
  gap: 25px;
}

.library-filter-popularity {
  position: relative;
}

.library-filter-popularity::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/images/ArrowsDownUp.svg");
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}

.library-filter-popularity input {
  padding-left: 40px;
}

.library-filter-chapter {
  position: relative;
}

.library-filter-chapter::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/images/view-list.svg");
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}

.library-filter-chapter input {
  padding-left: 40px;
}

.library-filter-author {
  position: relative;
}

.library-filter-author::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/images/woman.svg");
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}

.library-filter-author input {
  padding-left: 40px;
}

.library-filter-year {
  position: relative;
}

.library-filter-year::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/images/table-file.svg");
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}

.library-filter-year input {
  padding-left: 40px;
}

.library-list {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  flex-wrap: wrap;
}

.library-list-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 32%;
  background-color: #FFF;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 2.74px 105.6px 0 rgba(0, 0, 0, 0.04);
  margin-bottom: 30px;
  transition: 0.5s;
}

.library-list-item:hover {
  background-color: rgba(235, 245, 255, 1);
  cursor: pointer;
}

.library-list-item:hover > .library-el-btn {
  border: 1.33px solid rgba(250, 132, 67, 1);
  background-color: rgba(250, 132, 67, 1);
  color: #FFF;
  padding: 12px 5px;
}

.library-list-item:hover > .library-el-btn svg{
  display: inline;
}

.book-title {
  color: rgba(50, 67, 109, 1);
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
  letter-spacing: -1.5px;
  font-family: "Lato", sans-serif;
}

.truncate-title-book {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.book-desc {
  color: rgba(148, 151, 161, 1);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: "Lato", sans-serif;
}

.library-el-btn {
  border: 1.33px solid rgba(16, 144, 203, 1);
  color: rgba(16, 144, 203, 1);
  border-radius: 13px;
  padding: 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  background-color: #FFF;
  transition: 0.5s;
  display: flex;
  justify-content: space-around;
}

.library-el-btn svg {
  display: none;
}

.library-paper-btn {
  border: 1.33px solid rgba(16, 144, 203, 1);
  color: #FFF;
  border-radius: 13px;
  padding: 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  background-color: rgba(16, 144, 203, 1);
}

.library-pagination {
  text-align: center;
  margin-top: 30px;
}

.library-pagination .el-pagination.is-background .btn-next {
  border-radius: 50%;
  border: 1px solid rgba(241, 241, 241, 1);
  background-color: #FFF;
  width: 43px;
  height: 43px;
  line-height: 43px;
  font-weight: 600;
  font-size: 18px;
}

.library-pagination .el-pagination.is-background .btn-prev {
  border-radius: 50%;
  border: 1px solid rgba(241, 241, 241, 1);
  background-color: #FFF;
  width: 43px;
  height: 43px;
  line-height: 43px;
  font-weight: 600;
  font-size: 18px;
}

.library-pagination  .el-pagination.is-background .el-pager li {
  border-radius: 50%;
  border: 1px solid rgba(241, 241, 241, 1);
  background-color: #FFF;
  width: 43px;
  height: 43px;
  line-height: 43px;
  font-weight: 600;
  font-size: 18px;
}

.library-pagination  .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgba(16, 144, 203, 1);
}

.library-filter-popularity input::placeholder,
.library-filter-chapter input::placeholder,
.library-filter-author input::placeholder,
.library-filter-year input::placeholder{
  color: rgba(242, 242, 242, 1);
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.modal-book-title {
  display: flex;
  gap: 16px;
}

.modal-book-title div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.modal-book-title p {
  margin: 0;
  padding: 0;
  word-break: normal;
}

.modal-book-card {
  margin: 30px auto;
  width: 300px;
  border: 2px solid rgba(16, 144, 203, 1);
  border-radius: 12px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.modal-book-card::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  top: 16px;
  right: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/images/checkbox-tick.svg");
}

.price-tag {
  color: rgba(50, 67, 109, 1);
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  font-family: "Inter", sans-serif;
}

.book-card-desc {
  color: rgba(50, 67, 109, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  word-break: normal;
}

.btn-cancel {
  width: 100%;
  border: 1px solid rgba(208, 213, 221, 1);
  border-radius: 8px;
  padding: 10px 18px;
  color: rgba(50, 67, 109, 1);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
}

.btn-buy {
  width: 100%;
  border: 1px solid rgba(208, 213, 221, 1);
  border-radius: 8px;
  padding: 10px 18px;
  color: #FFF;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  background: rgba(16, 144, 203, 1);
  margin-top: 10px;
}

.modal-book-subtitle {
  color: rgba(50, 67, 109, 1);
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  font-family: "Inter", sans-serif;
}

.modal-book-text {
  color: rgba(50, 67, 109, 1);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
}

.download-book-img {
  margin-top: 40px;
  margin-bottom: 23px;
  text-align: center;
}

.download-book-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(16, 144, 203, 1);
  color: #FFF;
  text-decoration: none !important;
  padding: 20px;
  width: 100%;
  border-radius: 12px;
}

.download-book-link:hover {
  color: #FFF;
}

.modal-paper-book-card {
  display: flex;
  gap: 32px;
  padding: 15px;
  border: 1.5px solid rgba(16, 144, 203, 1);
  border-radius: 7px;
  max-width: 250px;
  margin: 20px 0;
}

.modal-paper-book-card .book-title {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  font-family: "Inter", sans-serif;
  color: rgba(71, 84, 103, 1);
  word-break: normal;
  letter-spacing: 0px;
}

.modal-paper-book-card .book-price {
  font-weight: 600;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  color: rgba(50, 67, 109, 1);
  margin-top: 18px;
}

.sending-column-data {
  min-width: 200px;
}

.sending-list-data {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.demarcation-line {
  width: 100%;
  height: 1px;
  background: rgba(228, 231, 236, 1);
}

.btns-paper-book {
  display: flex;
  gap: 15px;
}

.btns-paper-book .btn-buy {
  margin-top: 0;
}

</style>
